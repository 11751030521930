<ion-header class="ion-no-border" translucent>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-outline" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

	<!-- Payment with Bank Transfer -->
	<div class="proj-container-vertical">
	  <div class="proj-container-horizontal">
      <h5 class="text-semibold">{{lang.detail_transaction}}</h5>
      <div *ngIf="type == 1">
        <div *ngFor="let ticket of transaction.detail">
          <div class="d-flex psmall mb-1 text-semibold">
            <p class="mb-0">{{ticket.ticket_name}}</p>
            <div class="ml-auto">
              <p class="mb-0">{{ticket.ticket_qty}}x</p>
            </div>
            <div class="ml-2">
              <p class="mb-0"> {{ticket.ticket_price|currency:'IDR'}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="type == 2">
        <div *ngFor="let detail of transaction.detail">
          <div class="d-flex psmall mb-1 text-semibold">
            <p class="mb-0">{{detail.product_name}}</p>
            <div class="ml-auto">
              <p class="mb-0">{{detail.qty}}x</p>
            </div>
            <div class="ml-2">
              <p class="mb-0"> {{detail.product_price_after_discount_total|currency:'IDR'}}</p>
            </div>
          </div>
        </div>
      </div>

	    <hr/>
      <div class="d-flex psmall mb-1">
        <p class="mb-0">{{lang.trans_detail.subtotal}} ({{totalItems}} {{lang.trans_detail.items}})</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">{{subTotal|currency:'IDR'}}</p>
        </div>
      </div>
      <div *ngIf="type == 2" class="d-flex psmall mb-1">
        <p class="mb-0">{{lang.trans_detail.shipping_charge}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">{{shippingPrice|currency:'IDR'}}</p>
        </div>
      </div>
      <div class="d-flex psmall mb-1">
        <p class="mb-0">{{lang.trans_detail.service_charge}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">{{service_charge|currency:'IDR'}}</p>
        </div>
      </div>
      <div class="d-flex psmall mb-1">
        <p class="mb-0">{{lang.trans_detail.ppn}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">{{ppn|currency:'IDR'}}</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <p class="mb-0">{{lang.trans_detail.total}}</p>
        </ion-col>
        <ion-col>
          <ion-text color="primary"><h5 class="ion-text-right mb-0"> {{totalPrice|currency:'IDR'}}</h5></ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
