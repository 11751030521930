import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { take, switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Plugins } from '@capacitor/core';
import { from } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  language;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  getOnGoingTicketTransaction(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/transaction/ticket/check/ongoing/${eventId}`,
          httpOptions
        );
      })
    );
  }

  getPaymentMethod() {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/payment/payment_methods`,
          httpOptions
        );
      })
    );
  }

  updateTicketPayment(params) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('transaction_id', params.transaction_id);
        body.append('payment_id', params.payment_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/transaction/ticket/payment/payment_method`,
          body,
          httpOptions
        );
      })
    );
  }

  updatePaymentShop(params, cc = false) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('event_id', params.event_id);
        body.append('member_id', params.member_id);
        body.append('member_fullname', params.member_fullname);
        body.append('member_phone', params.member_phone);
        body.append('member_email', params.member_email);
        body.append('payment_id', params.payment_id);
        body.append('payment_code', params.payment_code);
        body.append('is_multiple_delivery', params.is_multiple_delivery);
        body.append('provinsi_id', params.provinsi_id);
        body.append('kabupaten_id', params.kabupaten_id);
        body.append('kecamatan_id', params.kecamatan_id);
        body.append('desa_id', params.desa_id);
        body.append('delivery_location', params.delivery_location);
        body.append('promo_id', params.promo_id);
        body.append('promo_code', params.promo_code);
        body.append('promo_amount', params.promo_amount);
        body.append('is_take_away', params.is_take_away);
        body.append('take_away_price', params.take_away_price);
        body.append('service_charge', params.service_charge);
        body.append('service_price', params.service_price);
        body.append('ppn_charge', params.ppn_charge);
        body.append('ppn_price', params.ppn_price);

        params.items.forEach(e => {
          body.append('product_ids[]', e.product_ids);
          body.append('product_delivery_method_ids[]', e.product_delivery_method_ids);
          body.append('qtys[]', e.qtys);
          body.append('notes[]', e.notes);
        });

        if (cc == false) {
          params.add_params.forEach(e => {
            body.append(e.payment_attribute_name, e.value);
          });
        } else {
          body.append('token_id', params.token_id);
          body.append('authentication_id', params.authentication_id);
          body.append('amount', params.transaction_price);
          body.append('card_cvn', params.card_cvn);
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.API2Url}/kelolain_transaction/add_transaction_v2`,
          body,
          httpOptions
        );
      })
    );
  }

  getTransactionTicket(transactionTicketId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/transaction/ticket/${transactionTicketId}`,
          httpOptions
        );
      })
    );
  }

  getTransactionTicketDetail(transactionTicketId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/transaction/ticket/detail/${transactionTicketId}`,
          httpOptions
        );
      })
    );
  }

  getTransactionTicketPaymentDetail(transactionTicketId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/transaction/ticket/payment/${transactionTicketId}`,
          httpOptions
        );
      })
    );
  }

  getMyTicketTransaction(status,page=1) {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/member/ticket_list/${status}?page=${page}`,
          httpOptions
        );
      })
    );
  }

  getMyTicketTransactionDetail(transaction_id) {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/member/ticket/${transaction_id}`,
          httpOptions
        );
      })
    );
  }

  onGoingTicketTransaction() {
    return from(Plugins.Storage.get({ key: 'transactionTicket' }))
      .pipe(
        map(storedData => {
          if (!storedData || !storedData.value) {
            return null;
          }
          const parsedData = JSON.parse(storedData.value) as {
            transaction_id: any;
          };
          return parsedData;
        })
      );
  }

  setTransactionTicketSession(transaction_id) {
    const transactionTicketData = JSON.stringify({
      transaction_id: transaction_id
    });
    Plugins.Storage.set({ key: 'transactionTicket', value: transactionTicketData });
  }

  removeTransactionTicketSession() {
    Plugins.Storage.remove({ key: 'transactionTicket' });
  }

  getMyShopTransaction(status: string, page=1) {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/shop/transactions/list/${status}?page=${page}`,
          httpOptions
        );
      })
    );
  }


  getMyShopTransactionDetail(transactionId: number) {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/shop/transactions/detail/${transactionId}`,
          httpOptions
        );
      })
    );
  }

  getTransactionShopPaymentDetail(transaction_id: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/transaction/shop/payment/${transaction_id}`,
          httpOptions
        );
      })
    );
  }

  changeTransactionPaymentMethod(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('transaction_id', params.transaction_id);
        body.append('payment_id', params.payment_id);
        body.append('transaction_type',params.transaction_type);
        if(params.phone) {
          body.append('phone',params.phone);
        }
        if(params.token_id) {
          body.append('token_id',params.token_id);
        }
        if(params.authentication_id) {
          body.append('authentication_id',params.authentication_id);
        }
        if(params.card_cvn) {
          body.append('card_cvn',params.card_cvn);
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          // `${environment.baseUrl}/api/transaction/payment/payment_method`,
          `${environment.API2Url}/kelolain_transaction/change_payment`,
          body,
          httpOptions
        );
      })
    );
  }

  cancelTransaction(params) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('transaction_id', params.transaction_id);
        body.append('transaction_type',params.transaction_type);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/transaction/cancel`,
          body,
          httpOptions
        );
      })
    );
  }

  getSelfPickUpItem(transaction_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('transaction_id', transaction_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/event/shop/transactions/self_pickup_items`,
          body,
          httpOptions
        );
      })
    );
  }

}
