<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
    <div class="proj-container-horizontal">
      <h5>Shipping Options</h5>

      <ion-list>
        <ion-radio-group>
          <ion-item class="ion-no-padding ion-no-margin">
            <ion-radio slot="start"></ion-radio>
            <ion-label class="ion-text-wrap">
              <div class="text-bold">Same day Rp 20.000</div>
              <div class="text-light">Pengiriman dalam waktu 6 - 8 Jam</div>
            </ion-label>
          </ion-item>
          <ion-item class="ion-no-padding ion-no-margin">
            <ion-radio slot="start"></ion-radio>
            <ion-label class="ion-text-wrap">
              <div class="text-bold">Reguler Rp 9.000</div>
              <div class="text-light">Diterima 2 - 7 hari setelah paket diserahkan kurir</div>
            </ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </div>
</ion-content>

<ion-footer class="box-light" mode="ios">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="popupClose()" style="width: 100%;">Confirmation</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
