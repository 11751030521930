<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
    <ion-searchbar [placeholder]="lang.search" mode="ios" (ionChange)="cari($event)"></ion-searchbar>
		<ion-buttons slot="end">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content class="ion-no-padding">

      <ion-list>
        <ion-item>
          <ion-label class="ion-text-wrap" (click)="get_current_loc()">
            <ion-icon name="location-outline"></ion-icon> {{lang.use_current_location}}
          </ion-label>
        </ion-item>

        <ion-item *ngFor="let db of data" (click)="get_selected_loc(db.place_id,db.structured_formatting.main_text)">
          <ion-label class="ion-text-wrap">
            <div class="text-bold">{{db.structured_formatting.main_text}}</div>
            <div class="text-light">{{db.structured_formatting.secondary_text}}</div>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
