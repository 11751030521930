import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-popup-size',
  templateUrl: './popup-size.page.html',
  styleUrls: [ './popup-size.page.scss' ],
})
export class PopupSizePage implements OnInit {

  s_xl = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
  s_l = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
  s_m = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
  s_s = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  size(val_size) {
    if (val_size == 'xl') {
      this.s_xl = "--box-shadow:none;"
      this.s_l = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_m = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_s = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
    }
    if (val_size == 'l') {
      this.s_xl = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_l = "--box-shadow:none;"
      this.s_m = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_s = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
    }
    if (val_size == 'm') {
      this.s_xl = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_l = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_m = "--box-shadow:none;"
      this.s_s = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
    }
    if (val_size == 's') {
      this.s_xl = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_l = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_m = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;"
      this.s_s = "--box-shadow:none;"
    }
  }

  popupClose() {
    this.modalController.dismiss();
  }

}
