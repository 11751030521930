import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TransactionService } from 'src/app/services/transaction.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent implements OnInit {
  @Input() transaction_id;
  @Input() type;

  subTotal: number = 0;
  totalPrice: number = 0;
  totalItems: number = 0;

  transaction: any = {};
  service_charge: number = 0;
  ppn: number = 0;
  ppnPercentage: number = 0;
  shippingPrice: number = 0;
  lang: any = {
    trans_detail: {}
  };

  constructor(
    private modalCtrl: ModalController,
    private transService: TransactionService,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('payment').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {
    if(this.type == 1) {
      this.transService.getTransactionTicket(this.transaction_id)
      .subscribe(
        res => {
          this.transaction = res.data
          this.subTotal = res.data.transaction_ticket_price;
          this.totalPrice = res.data.transaction_ticket_total_price;
          this.ppn = res.data.transaction_ticket_ppn;
          this.service_charge = res.data.transaction_ticket_service_charge;
          this.totalItems = res.data.transaction_ticket_qty;
        },
        err => {

        }
      );
    } else if (this.type == 2) {
      this.transService.getMyShopTransactionDetail(this.transaction_id)
      .subscribe(
        res => {
          this.transaction = res.data;
          this.subTotal = res.data.transaction_price - res.data.transaction_discount;
          this.totalPrice = res.data.grand_total;
          this.ppn = res.data.ppn_price;
          this.service_charge = res.data.service_price;
          this.totalItems = res.data.total_qty;
          this.shippingPrice = res.data.delivery_price;
        },
        err => {

        }
      );
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
