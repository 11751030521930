import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
// import { type } from 'os';
import { EventService } from 'src/app/services/event.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-popup-qty',
  templateUrl: './popup-qty.page.html',
  styleUrls: ['./popup-qty.page.scss'],
})
export class PopupQtyPage implements OnInit {

  eventId: string;
  product_id: string;
  product_image: string;
  product_name: string;
  product_price: number;
  product_category: string;
  product_type: string;
  product_model: any = [];

  selected_0: string = "--background:#F1F2F6;--box-shadow:none;color:#BDBDBD;";
  selected_1: string = "--box-shadow:none;";

  qty: number = 1;
  lang: any = {};

  constructor(
    private eventService: EventService,
    public modalController: ModalController,
    public navParams: NavParams,
    private router: Router,
    private toastController : ToastController,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('shop').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {
    this.product_model = [];

    this.eventId = this.navParams.get('event_id');
    this.product_id = this.navParams.get('product_id');
    this.product_image = this.navParams.get('product_image');
    this.product_name = this.navParams.get('product_name');
    this.product_price = this.navParams.get('product_price');
    this.product_category = this.navParams.get('product_category');

    let types: any = this.navParams.get('product_model');
    let arr_length = types.length;
    let selected: string;

    for (let i = 0; i < arr_length; i++) {
      if (i == 0) { selected = this.selected_1 } else { selected = this.selected_0 }

      this.product_type = types[0].product_model_label;

      this.product_model.push({
        product_id: types[i].product_id,
        product_model_label: types[i].product_model_label,
        product_price: types[i].product_price,
        selected: selected,
      })
    }
  }

  pilih(val_id) {
    let selected: string;

    for (let i = 0; i < this.product_model.length; i++) {
      if (this.product_model[i].product_id == val_id) {
        this.product_model[i].selected = this.selected_1;
        this.product_id = val_id;
        this.product_type = this.product_model[i].product_model_label;
        this.product_price = this.product_model[i].product_price;
      } else {
        this.product_model[i].selected = this.selected_0;
      }
    }
  }

  qty_plus() {
    this.qty += 1;
  }

  qty_minus() {
    if (this.qty > 1) {
      this.qty -= 1;
    }
  }

  addToCart(type) {
    this.modalController.dismiss();

    let params = {
      event_id: this.eventId,
      product_id: this.product_id,
      product_qty: this.qty,
      delivery_option: 1,
      notes: ''
    };
    this.eventService.cartAdd(params).subscribe(
      res => {
        console.info(JSON.stringify(res));
        if (type==1){
          this.presentToast('Pesanan Anda telah berhasil ditambahkan');
        }else{
        this.router.navigate(['/cart/' + this.eventId]);
        }
      },
      errRes => {
        console.error(JSON.stringify(errRes));
      }
    );
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }



  popupClose() {
    this.modalController.dismiss();
  }

}
