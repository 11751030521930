<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="closeModal();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
	  <div class="proj-container-horizontal">
		  <h3 class="mb-1">TICKET CODE</h3>
		  <p class="mb-0">Please enter your Ticket Code</p>
	  </div>
  </div>
  <div class="proj-container-vertical">
	  <div class="proj-container-horizontal">
		<form>
		  <ion-list lines="full" class="ion-margin-bottom ion-no-padding">
			  <ion-item class="ion-margin-bottom">
				<ion-input type="number" name="email" placeholder="Ticket code" required></ion-input>
			  </ion-item>
		  </ion-list>
		</form>
	  </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="proj-container-vertical">
      <div class="proj-container-horizontal">
        <ion-button type="submit" expand="block" class="ion-no-margin" color="primary">Next</ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
