import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private sbj = new Subject<any>();
  private sbjShop = new BehaviorSubject(0);
  manageAddress = this.sbjShop.asObservable();
  language;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  getAddressList() {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/member/address/address_list`, httpOptions
        );
      })
    );
  }

  addAddress(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('primary_address', params.primary_address);
        body.append('street', params.street);
        body.append('district', params.district);
        body.append('city', params.city);
        body.append('province', params.province);
        body.append('postal_code', params.postal_code);
        body.append('latitude', params.latitude);
        body.append('longitude', params.longitude);
        body.append('detail_address', params.detail_address);
        body.append('label', params.label);
        body.append('receiver_name', params.receiver_name);
        body.append('receiver_phone', params.receiver_phone);
        body.append('provinsi_id', params.provinsi_id);
        body.append('kabupaten_id', params.kabupaten_id);
        body.append('kecamatan_id', params.kecamatan_id);
        body.append('desa_id', params.desa_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/address/add_address`,
          body,
          httpOptions
        );
      })
    );
  }
  pubAddAddress(data: any) { this.sbj.next(data); }
  obvAddAddress(): Subject<any> { return this.sbj; }

  pubAddAddressShop(data: any) { this.sbjShop.next(data); }

  updateAddress(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('primary_address', params.primary_address);
        body.append('address_id', params.id);
        body.append('street', params.street);
        body.append('district', params.district);
        body.append('city', params.city);
        body.append('province', params.province);
        body.append('postal_code', params.postal_code);
        body.append('latitude', params.latitude);
        body.append('longitude', params.longitude);
        body.append('detail_address', params.detail_address);
        body.append('label', params.label);
        body.append('receiver_name', params.receiver_name);
        body.append('receiver_phone', params.receiver_phone);
        body.append('provinsi_id', params.provinsi_id);
        body.append('kabupaten_id', params.kabupaten_id);
        body.append('kecamatan_id', params.kecamatan_id);
        body.append('desa_id', params.desa_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/address/edit_address`,
          body,
          httpOptions
        );
      })
    );
  }
  pubUpdateAddress(data: any) { this.sbj.next(data); }
  obvUpdateAddress(): Subject<any> { return this.sbj; }

  deleteAddress(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('address_id', params.id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/address/del_address`,
          body,
          httpOptions
        );
      })
    );
  }
  pubDeleteAddress(data: any) { this.sbj.next(data); }
  obvDeleteAddress(): Subject<any> { return this.sbj; }


  //-----LOCATION-----
  // getProvinceList() { return this.http.get<any>(`${environment.baseUrl_location}/provinsi`); }
  // getCityList(params: any) {
  //   let body = new FormData();
  //   body.append('provinsi_id', params.provinsi_id);
  //   return this.http.post<any>(
  //     `${environment.baseUrl_location}/kabupaten`, body
  //   );
  // }
  // getDistrictList(params: any) {
  //   let body = new FormData();
  //   body.append('kabupaten_id', params.kabupaten_id);
  //   return this.http.post<any>(
  //     `${environment.baseUrl_location}/kecamatan`, body
  //   );
  // }
  getLocationList(params: any) {
    let body = new FormData();
    body.append('search', params.search);
    return this.http.post<any>(
      `${environment.baseUrl_location}/get_3_level`, body
    );
  }
  pubLocation(data: any) { this.sbj.next(data); }
  obvLocation(): Subject<any> { return this.sbj; }

  editProfile(params) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('fullname',params.fullname);
        body.append('company',params.company);
        body.append('position',params.position);
        body.append('language',this.language);
        if(params.email || params.email != ''){
          body.append('email',params.email);
        }
        if(params.phone || params.phone != ''){
          body.append('phone',params.phone);
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/profile/edit`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  changeMemberPassword(newPassword: string, currentPassword: string) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('new-password',newPassword);
        body.append('current-password',currentPassword);
        body.append('language',this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/profile/change_password`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  checkPassword() {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/member/profile/change_password`,
          httpOptions
        );
      }),
      take(1)
    );
  }

  phoneVerification(otp: string) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('otp',otp);
        body.append('language',this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/phone_verification`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  resendOTP() {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('language',this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/phone_verification/resend_otp`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  getOTPExpireDate() {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/member/otp/expire`,
          httpOptions
        );
      }),
      take(1)
    );
  }

  getMemberProfile() {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/member/member_profile`,
          httpOptions
        );
      }),
      take(1)
    );
  }

  resendEmailVerification(email) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('email',email);
        body.append('language',this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/email_verification/resend_email`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  getMyGalleryImage(member_id) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/view_gallery`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  deleteGalleryImage(gallery_id) {
    return this.authService.token.pipe(
      switchMap(token => {
        let body = new FormData();
        body.append('gallery_id',gallery_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.baseUrl}/api/member/delete_gallery_image`,
          body,
          httpOptions
        );
      }),
      take(1)
    );
  }

  deleteProfilePicture(member_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('member_id', member_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/profile/delete_profile_pic`,
          body,
          httpOptions
        );
      })
    );
  }

  getProvinceList() {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.API3Url}/get_data/provinsi`,
          httpOptions
        );
      }),
      take(1)
    );
  }

  getKabupatenList(provinsi_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('provinsi_id', provinsi_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.API3Url}/get_data/kabupaten`,
          body,
          httpOptions
        );
      })
    );
  }

  getKecamatanList(kabupaten_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('kabupaten_id', kabupaten_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.API3Url}/get_data/kecamatan`,
          body,
          httpOptions
        );
      })
    );
  }

  getDesaList(kecamatan_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('kecamatan_id', kecamatan_id);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.API3Url}/get_data/desa`,
          body,
          httpOptions
        );
      })
    );
  }

  getMyFiles(type) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/file/myfiles/${type}`, httpOptions
        );
      })
    );
  }

  uploadPhoto(file : File, member_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('member_id', member_id);
        body.append('member_image', file, file.name);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.API2Url}/kelolain/update_profile_picture`,
          body,
          httpOptions
        );
      })
    );
  }

  uploadMediaEvent(file, postid) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('media', file, file.name);
        body.append('post_id', postid);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/timeline/post/add_media`,
          body,
          httpOptions
        );

      })
    );
  }

}
