<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="closeModal()">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
    <ion-item lines="none" class="mb-2">
      <ion-thumbnail slot="start"><img [src]="payment.payment_image" alt=""></ion-thumbnail>
      <ion-label>{{payment.payment_name}}</ion-label>
    </ion-item>
    <div class="proj-container-horizontal">
      <form #f="ngForm" (ngSubmit)="next(f)">
        <ion-item *ngFor="let attribute of payment_attribute;let i = index" class="ion-no-padding ion-margin-bottom">
          <ion-label mode="ios" position="stacked">{{attribute.payment_attribute_text}}</ion-label>
          <ion-input [type]="attribute.input_type" [(ngModel)]="attribute.value" [name]="attribute.payment_attribute_name" [placeholder]="attribute.payment_attribute_placeholder" required></ion-input>

        </ion-item>
      </form>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="proj-container-vertical">
      <div class="proj-container-horizontal">
        <ion-button type="submit" expand="block" [disabled]="!f.valid" class="ion-no-margin" color="primary" (click)="f.ngSubmit.emit()">{{lang.next}}</ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
