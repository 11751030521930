<ion-toolbar class="proj-container-horizontal">
  <ion-icon name="close" fill="outline" color="dark" style="font-size: 24px;" slot="end" (click)="popupClose()">
  </ion-icon>
</ion-toolbar>

<!-- <ion-content> -->
<ion-card>
  <ion-card-content>
    <ion-item class="ion-no-padding" lines="none" style="margin-bottom: 20px;">
      <ion-thumbnail slot="start">
        <img src="assets/shop/1.png">
      </ion-thumbnail>
      <ion-label class="ion-text-wrap">
        <div class="text-semibold">Rp 175.000</div>
        <div class="des">T Shirt Fourtwnty - Fana Merah Jambu</div>
      </ion-label>
    </ion-item>

    <div class="proj-textlight text-semibold">Clothes Size</div>

    <ion-button class="btn_size" size="small" [style]="s_xl" (click)="size('xl')">XL</ion-button>
    <ion-button class="btn_size" size="small" [style]="s_l" (click)="size('l')">L</ion-button>
    <ion-button class="btn_size" size="small" [style]="s_m" (click)="size('m')">M</ion-button>
    <ion-button class="btn_size" size="small" [style]="s_s" (click)="size('s')">S</ion-button>
  </ion-card-content>
</ion-card>
<!-- </ion-content> -->

<ion-footer class="box-light" mode="ios">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button expand="block" (click)="popupClose()">Confirmation</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>