import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventTicket } from 'src/app/models/event.model';
import { map } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-detail-transaction',
  templateUrl: './detail-transaction.component.html',
  styleUrls: ['./detail-transaction.component.scss'],
})
export class DetailTransactionComponent implements OnInit {
  @Input() ticket_ids;
  @Input() ticket_map;
  @Input() event_id;

  event_ticket: EventTicket[];
  subTotal: number = 0;
  totalPrice: number = 0;
  totalTicket: number = 0;

  service_charge: number = 0;
  ppn: number = 0;
  ppnPercentage: number = 0;
  lang: any = {
    trans_detail: {}
  };

  constructor(
    private modalCtrl: ModalController,
    private eventService: EventService,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('payment').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {
    this.eventService.getEventServiceCharge(this.event_id)
    .subscribe(
      resp => {
        if(resp.data.event_is_service == 1) {
          this.service_charge = resp.data.event_additional_service_nominal;
        }
        if(resp.data.event_is_ppn == 1) {
          this.ppnPercentage = 0.1; //ppn sudah pasti 10%
        }
        this.eventService.getEventTicket(this.event_id)
        .pipe(
          map(res => {
            return res.data;
          }),
          map(res => {
            return res.filter((data) => {
              if (this.ticket_ids.includes(data.ticket_id.toString())) {
                this.ticket_map.map((qty) => {
                  if (qty.ticket_id == data.ticket_id) {
                    data.ticket_qty = qty.ticket_qty;
                  }
                })
                return data;
              }
              return null;
            })
          })
        )
        .subscribe(
          res => {
            this.event_ticket = res;
            this.event_ticket.forEach((ticket, index) => {
              this.totalTicket += Number(ticket.ticket_qty);
              this.subTotal += (ticket.ticket_price * ticket.ticket_qty);
            });
            //total price calculation with ppn & service charge
            this.ppn = (this.ppnPercentage * this.subTotal);
            this.totalPrice = this.subTotal + this.service_charge + this.ppn;
          },
          errRes => {
          }
        );
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
