import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./account/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home-tabs',
    loadChildren: () => import('./home/home-tabs/home-tabs.module').then(m => m.HomeTabsPageModule)
  },
  {
    path: 'sessionlist/:eventId',
    loadChildren: () => import('./event/session/sessionlist/sessionlist.module').then(m => m.SessionlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-event/:eventId',
    loadChildren: () => import('./event/detail-event/detail-event.module').then(m => m.DetailEventPageModule)
  },
  {
    path: 'eventhome/:eventId',
    loadChildren: () => import('./event/eventhome/eventhome.module').then(m => m.EventhomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sessiondetail/:eventId/:sessionId',
    loadChildren: () => import('./event/session/sessiondetail/sessiondetail.module').then(m => m.SessiondetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sponsorlist/:eventId',
    loadChildren: () => import('./event/sponsor/sponsorlist/sponsorlist.module').then(m => m.SponsorlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sponsordetail/:eventId/:sponsorId',
    loadChildren: () => import('./event/sponsor/sponsordetail/sponsordetail.module').then(m => m.SponsordetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'speakerlist/:eventId',
    loadChildren: () => import('./event/speaker/speakerlist/speakerlist.module').then(m => m.SpeakerlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'speakerdetail/:eventId/:speakerId',
    loadChildren: () => import('./event/speaker/speakerdetail/speakerdetail.module').then(m => m.SpeakerdetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'exhibitorlist/:eventId',
    loadChildren: () => import('./event/exhibitor/exhibitorlist/exhibitorlist.module').then(m => m.ExhibitorlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'exhibitordetail/:eventId/:exhibitorId',
    loadChildren: () => import('./event/exhibitor/exhibitordetail/exhibitordetail.module').then(m => m.ExhibitordetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'myqr/:eventId',
    loadChildren: () => import('./profiles/myqr/myqr.module').then(m => m.MyqrPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'contactcardlist/:eventId',
    loadChildren: () => import('./event/contactcard/contactcardlist/contactcardlist.module').then(m => m.ContactcardlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'contactcarddetail/:eventId/:contactId',
    loadChildren: () => import('./event/contactcard/contactcarddetail/contactcarddetail.module').then(m => m.ContactcarddetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'faqlist/:eventId',
    loadChildren: () => import('./event/faq/faqlist/faqlist.module').then(m => m.FaqlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'forget',
    loadChildren: () => import('./account/forget/forget.module').then(m => m.ForgetPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./profiles/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./account/registration/registration.module').then(m => m.RegistrationPageModule)
  },
  {
    path: 'activation-acc/:token',
    loadChildren: () => import('./account/activation-acc/activation-acc.module').then(m => m.ActivationAccPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./general/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-service',
    loadChildren: () => import('./general/terms-service/terms-service.module').then(m => m.TermsServicePageModule)
  },
  {
    path: 'myevent',
    loadChildren: () => import('./profiles/myevent/myevent.module').then(m => m.MyeventPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-message/:messageId',
    loadChildren: () => import('./profiles/detail-message/detail-message.module').then(m => m.DetailMessagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'language',
    loadChildren: () => import('./general/language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'notification-detail/:type/:notifId',
    loadChildren: () => import('./profiles/notification-detail/notification-detail.module').then(m => m.NotificationDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-video',
    loadChildren: () => import('./detail-video/detail-video.module').then(m => m.DetailVideoPageModule)
  },
  {
    path: 'buy-ticket/:eventId',
    loadChildren: () => import('./transaction/buyticket/buy-ticket/buy-ticket.module').then(m => m.BuyTicketPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ikoot-tv',
    loadChildren: () => import('./profiles/ikoot-tv/ikoot-tv.module').then(m => m.IkootTvPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./profiles/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-payment',
    loadChildren: () => import('./transaction/buyticket/detail-payment/detail-payment.module').then(m => m.DetailPaymentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'new-password/:token',
    loadChildren: () => import('./account/new-password/new-password.module').then(m => m.NewPasswordPageModule)
  },
  {
    path: 'material/:eventId',
    loadChildren: () => import('./event/material/material.module').then(m => m.MaterialPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'brochure/:eventId',
    loadChildren: () => import('./event/brochure/brochure.module').then(m => m.BrochurePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'badgelist/:eventId',
    loadChildren: () => import('./event/badge/badgelist/badgelist.module').then(m => m.BadgelistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'badgedetail/:eventId/:badgeId',
    loadChildren: () => import('./event/badge/badgedetail/badgedetail.module').then(m => m.BadgedetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'points/:eventId',
    loadChildren: () => import('./event/points/points.module').then(m => m.PointsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'myticket-list',
    loadChildren: () => import('./profiles/myticket/myticket-list/myticket-list.module').then(m => m.MyticketListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'myticket-detail-transaction/:transactionId',
    loadChildren: () => import('./profiles/myticket/myticket-detail-transaction/myticket-detail-transaction.module').then(m => m.MyticketDetailTransactionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'transaction-tabs',
    loadChildren: () => import('./profiles/mytransaction/transaction-tabs/transaction-tabs.module').then(m => m.TransactionTabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'transaction-complete',
    loadChildren: () => import('./profiles/mytransaction/transaction-complete/transaction-complete.module').then(m => m.TransactionCompletePageModule),
    canLoad: [AuthGuard]

  },
  {
    path: 'transaction-current',
    loadChildren: () => import('./profiles/mytransaction/transaction-current/transaction-current.module').then(m => m.TransactionCurrentPageModule),
    canLoad: [AuthGuard]

  },
  {
    path: 'transaction-cancel',
    loadChildren: () => import('./profiles/mytransaction/transaction-cancel/transaction-cancel.module').then(m => m.TransactionCancelPageModule),
    canLoad: [AuthGuard]

  },
  {
    path: 'manage-address',
    loadChildren: () => import('./profiles/manage-address/manage-address.module').then(m => m.ManageAddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checkout-info',
    loadChildren: () => import('./transaction/buyticket/checkout-info/checkout-info.module').then(m => m.CheckoutInfoPageModule),
    canLoad: [AuthGuard]

  },
  {
    path: 'payment-method',
    loadChildren: () => import('./transaction/buyticket/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-trans-cancel',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-cancel/detail-trans-cancel.module').then(m => m.DetailTransCancelPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-trans-complete',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-complete/detail-trans-complete.module').then(m => m.DetailTransCompletePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-trans-selfpickup',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-selfpickup/detail-trans-selfpickup.module').then(m => m.DetailTransSelfpickupPageModule)
  },
  {
    path: 'detail-trans-current',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-current/detail-trans-current.module').then(m => m.DetailTransCurrentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checkout-successful/:ticketTransId',
    loadChildren: () => import('./transaction/buyticket/checkout-successful/checkout-successful.module').then(m => m.CheckoutSuccessfulPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checkout-successful-shop/:transcationId',
    loadChildren: () => import('./transaction/shop/checkout-successful-shop/checkout-successful-shop.module').then(m => m.CheckoutSuccessfulShopPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'mygallery',
    loadChildren: () => import('./profiles/mygallery/mygallery.module').then(m => m.MygalleryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'shop/:eventId',
    loadChildren: () => import('./transaction/shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'shop-detail/:eventId/:shopId',
    loadChildren: () => import('./transaction/shop/shop-detail/shop-detail.module').then(m => m.ShopDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'cart/:eventId',
    loadChildren: () => import('./transaction/shop/cart/cart.module').then(m => m.CartPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checkout/:eventId',
    loadChildren: () => import('./transaction/shop/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'mymenu',
    loadChildren: () => import('./home/mymenu/mymenu.module').then(m => m.MymenuPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'newpost/:eventId/:type',
    loadChildren: () => import('./event/timeline/newpost/newpost.module').then(m => m.NewpostPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'postlike/:postId',
    loadChildren: () => import('./event/timeline/postlike/postlike.module').then(m => m.PostlikePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'postcomment/:postId/:eventId',
    loadChildren: () => import('./event/timeline/postcomment/postcomment.module').then(m => m.PostcommentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'phone-verification',
    loadChildren: () => import('./profiles/phone-verification/phone-verification.module').then(m => m.PhoneVerificationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'favourite/:eventId',
    loadChildren: () => import('./event/favorite/favourite.module').then( m => m.FavouritePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'stuckpage',
    loadChildren: () => import('./general/stuckpage/stuckpage.module').then( m => m.StuckpagePageModule)
  },
  {
    path: 'payment-method-shop/:eventId/:shippingPrice/:addressId/:serviceCharge',
    loadChildren: () => import('./transaction/shop/payment-method-shop/payment-method-shop.module').then(m => m.PaymentMethodShopPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'select-address/:eventId',
    loadChildren: () => import('./transaction/shop/select-address/select-address.module').then(m => m.SelectAddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-shop-transaction-current/:transactionId',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-current/detail-trans-current.module').then(m => m.DetailTransCurrentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-shop-transaction-complete/:transactionId',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-complete/detail-trans-complete.module').then(m => m.DetailTransCompletePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'detail-shop-transaction-cancel/:transactionId',
    loadChildren: () => import('./profiles/mytransaction/detail-trans-cancel/detail-trans-cancel.module').then(m => m.DetailTransCancelPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'payment-info/:paymentId',
    loadChildren: () => import('./profiles/mytransaction/payment-info/payment-info.module').then( m => m.PaymentInfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'webview',
    loadChildren: () => import('./event/webview/webview.module').then( m => m.WebviewPageModule)
  },
  {
    path: 'changepayment/:type/:transactionId',
    loadChildren: () => import('./transaction/buyticket/changepayment/changepayment.module').then( m => m.ChangepaymentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'event-report/:eventId',
    loadChildren: () => import('./admin/event-report/event-report.module').then( m => m.EventReportPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'participant-list/:eventId/:date',
    loadChildren: () => import('./admin/participant-list/participant-list.module').then( m => m.ParticipantListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'attendee-list/:eventId/:date',
    loadChildren: () => import('./admin/attendee-list/attendee-list.module').then( m => m.AttendeeListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'attendee-checkout/:eventId/:date',
    loadChildren: () => import('./admin/attendee-checkout/attendee-checkout.module').then( m => m.AttendeeCheckoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./profiles/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'editpost/:postId',
    loadChildren: () => import('./event/timeline/editpost/editpost.module').then( m => m.EditpostPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'myfiles',
    loadChildren: () => import('./profiles/myfiles/myfiles.module').then( m => m.MyfilesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'eventinfo/:eventId',
    loadChildren: () => import('./event/eventinfo/eventinfo.module').then( m => m.EventinfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sessionquestionlist/:eventId',
    loadChildren: () => import('./admin/sessionquestionlist/sessionquestionlist.module').then( m => m.SessionquestionlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'questionlist/:eventId/:sessionId',
    loadChildren: () => import('./admin/questionlist/questionlist.module').then( m => m.QuestionlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'textarea',
    loadChildren: () => import('./event/textarea/textarea.module').then( m => m.TextareaPageModule)
  },
  {
    path: 'rsvp',
    loadChildren: () => import('./event/rsvp/rsvp.module').then( m => m.RSVPPageModule)
  },
  {
    path: 'rsvp-answer',
    loadChildren: () => import('./event/rsvp-answer/rsvp-answer.module').then( m => m.RsvpAnswerPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./general/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'preview-image',
    loadChildren: () => import('./event/preview-image/preview-image.module').then( m => m.PreviewImagePageModule)
  },
  {
    path: 'login-vip',
    loadChildren: () => import('./account/login-vip/login-vip.module').then( m => m.LoginVipPageModule)
  },
  {
    path: 'zoom',
    loadChildren: () => import('./event/session/zoom/zoom.module').then( m => m.ZoomPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./account/confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home-new/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'myinbox',
    loadChildren: () => import('./account/myinbox/myinbox.module').then( m => m.MyinboxPageModule)
  },
  {
    path: 'eventhome',
    loadChildren: () => import('./event-new/eventhome/eventhome.module').then( m => m.EventhomePageModule)
  },
  {
    path: 'event/:eventId',
    loadChildren: () => import('./event-new/event/event.module').then( m => m.EventPageModule)

  },
  {
    path: 'session',
    loadChildren: () => import('./event-new/session/session.module').then( m => m.SessionPageModule)
  },
  {
    path: "preview",
    loadChildren: () =>
      import("./event-new/session/sessionlist/preview/preview.module").then(
        (m) => m.PreviewPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: "zoom/:eventId/:sessionId",
    loadChildren: () =>
      import("./event-new/session/sessionlist/zoom/zoom.module").then(
        (m) => m.ZoomPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'network/:eventId',
    loadChildren: () => import('./network/event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'join-popup',
    loadChildren: () => import('./event-new/join-popup/join-popup.module').then( m => m.JoinPopupPageModule)
  },
  {
    path: 'faqdetail/:eventId/:faqId',
    loadChildren: () => import('./event-new/faqdetail/faqdetail.module').then( m => m.FaqdetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'surveydetail/:eventId/:surveyGroupId',
    loadChildren: () => import('./event-new/survey/surveydetail/surveydetail.module').then(m => m.SurveydetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'surveyfinish',
    loadChildren: () => import('./event-new/survey/surveyfinish/surveyfinish.module').then(m => m.SurveyfinishPageModule)
  },
  {
    path: 'survey-popup',
    loadChildren: () => import('./event-new/survey/survey-popup/survey-popup.module').then( m => m.SurveyPopupPageModule)
  },
  {
    path: "chat/:eventId/:topicId",
    loadChildren: () =>
      import("./event-new/networking/chat/chat.module").then(
        (m) => m.ChatPageModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () => import('./event-new/menu/menu.module').then( m => m.MenuPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: "always" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
