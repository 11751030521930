import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-popup-location',
  templateUrl: './popup-location.page.html',
  styleUrls: ['./popup-location.page.scss'],
})
export class PopupLocationPage implements OnInit {

  constructor(
    public modalController: ModalController,

  ) { }

  ngOnInit() {
  }

  popupClose() {
    this.modalController.dismiss();
  }

}
