import { Component, OnInit, Input } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-detail-transaction-shop',
  templateUrl: './detail-transaction-shop.page.html',
  styleUrls: ['./detail-transaction-shop.page.scss'],
})
export class DetailTransactionShopPage implements OnInit {
  @Input() eventId;
  @Input() shippingPrice;
  @Input() serviceCharge;

  subTotal: number = 0;
  totalPrice: number = 0;
  totalItems: number = 0;
  convenienceFee: number = 0;
  shopTransaction : any;
  lang: any = {
    trans_detail: {}
  };

  constructor(
    private modalCtrl: ModalController,
    private eventService: EventService,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('payment').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {

    this.eventService.cartList(this.eventId).subscribe(res => {
      this.shopTransaction = res.data;
      this.shopTransaction.forEach(shop => {
        this.totalPrice += shop.product_price_after_discount * shop.qty;

      });
      this.totalItems = this.shopTransaction.length;
    }, err => { console.error(err) });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
