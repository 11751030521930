import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.page.html',
  styleUrls: ['./preview-image.page.scss'],
})
export class PreviewImagePage implements OnInit {
  @Input() url;
  constructor(
    public modalController: ModalController,

  ) { }

  ngOnInit() {

  }

  async closeModal() {
    await this.modalController.dismiss();
  }

}
