<!-- <ion-header>
  <ion-toolbar>
    <ion-title>tutorial</ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content>
  <ion-slides pager="true" [options]="slideOpts" #slides (ionSlideReachEnd)="finish()" class="pagination-footer">
    <ion-slide *ngFor="let i of data" class="ion-align-items-center">
      <div class="d-block ion-text-center">
        <div class="proj-statement">
          <img [src]="i.img" class="image-block">
        </div>
        <h4 style="margin-bottom: 0; margin-top: 20px;">{{i.title}}</h4>
        <p>{{i.detail}}</p>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>


<ion-footer class="ion-no-border">
	<div class="proj-container-horizontal">
		<ion-toolbar>
			<ion-buttons slot="start">
        <ion-button type="submit" size="small" color="dark" class="btnPrev" (click)="skip()">Skip</ion-button>
			</ion-buttons>
			<ion-buttons slot="end">
        <ion-button type="submit" size="small" color="dark" class="btnNext" (click)="next()">{{nextButton}}</ion-button>
			</ion-buttons>
		</ion-toolbar>
  </div>
</ion-footer>
