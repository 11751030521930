import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialPipe } from 'src/app/pipes/initial.pipe';
import { TimesagoPipe } from 'src/app/pipes/timesago.pipe';
import { MomentPipe } from 'src/app/pipes/moment.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';

@NgModule({
  declarations: [InitialPipe, TimesagoPipe, MomentPipe, SafeHtmlPipe],
  imports: [
    CommonModule
  ],
  exports:[
    InitialPipe,
    TimesagoPipe,
    MomentPipe,
    SafeHtmlPipe
  ]
})
export class SharedModule { }
