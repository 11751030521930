<ion-toolbar class="proj-container-horizontal">
  <ion-icon name="arrow-back" color="dark" style="font-size: 24px;" slot="start" (click)="popupClose()">
  </ion-icon>
</ion-toolbar>

<ion-card>
  <ion-card-content class="ion-no-padding">
    <div class="ion-padding-horizontal ion-padding-top proj-textblack">
      <h4>Location</h4>
    </div>
    <ion-searchbar placeholder="Search Country" mode="ios"></ion-searchbar>

    <ion-list>
      <ion-item class="psmall" lines="none">
        <ion-checkbox slot="start" mode="ios" value="1"></ion-checkbox>
        Jabodetabek
      </ion-item>
      <ion-item class="psmall" lines="none">
        <ion-checkbox slot="start" mode="ios" value="2"></ion-checkbox>
        Kota Jakarta
      </ion-item>
      <ion-item class="psmall" lines="none">
        <ion-checkbox slot="start" mode="ios" value="3"></ion-checkbox>
        Kota Bekasi
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-card>

<ion-footer class="box-light" mode="ios">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="popupClose()" expand="block">Save</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>