import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  language;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  getEventSessionList(eventId, sessionHallId=null) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let param = '?session_hall_id=' + sessionHallId;

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/session${param}`, httpOptions
        );
      })
    );
  }
  
  getEventSessionHallList(eventId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/session_hall/${eventId}`, httpOptions
        );
      })
    );
  }

  getNextSession(eventId, member_type, current_session_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let param = '?event_id=' + eventId + '&member_type=' + member_type + '&current_session_id=' + current_session_id + '?language=' + this.language;

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/session/next_session${param}`, httpOptions
        );
      })
    );
  }

  getEventSessionDetail(sessionId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/session/${sessionId}`, httpOptions
        );
      })
    );
  }


  getEventSessionOngoing(eventId,type:string) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/${eventId}/session/${type}`, httpOptions
        );
      })
    );
  }

  getSessionQuestion(session_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('session_id',session_id);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/question`, body, httpOptions
        );
      })
    );
  }

  askQuestion(session_id, question) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('session_id',session_id);
        body.append('question',question);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/ask_question`, body, httpOptions
        );
      })
    );
  }

  getQuestionByQuestionId(question_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('session_question_id',question_id);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/question_id`, body, httpOptions
        );
      })
    );
  }

  joinSessionLiveStream(event_id, session_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('event_id',event_id);
        body.append('session_id',session_id);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/join`, body, httpOptions
        );
      })
    );
  }

  exitSessionLiveStream(event_id, session_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('event_id',event_id);
        body.append('session_id',session_id);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/exit`, body, httpOptions
        );
      })
    );
  }

  bookSession(event_id, session_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('event_id',event_id);
        body.append('session_id',session_id);
        body.append('language',this.language);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/session/booking`, body, httpOptions
        );
      })
    );
  }
}
