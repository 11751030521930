<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="closeModal();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-horizontal">
		<div class="proj-container-vertical t-0">
      <form #f="ngForm" (ngSubmit)="askQuestion(f)">
        <ion-label>Question</ion-label>
        <ion-textarea name="question" ngModel autoGrow placeholder="Ask anything..."></ion-textarea>
      </form>
		</div>
  </div>
</ion-content>

<ion-footer class="shadow ion-no-border">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="f.ngSubmit.emit()" expand="block" color="primary">Submit</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
