<ion-header class="ion-no-border" translucent>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="closeClockinModal();">
          <ion-icon name="close" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

<ion-content>
  <div class="container pt-3 pb-5">
    <!-- Payment with ovo -->
    <div class="d-flex">
        <img src="assets/media/icon-ovo.svg"/>
        <h4 class="ml-1 mb-0">OVO</h4>
    </div>
    <p class="psmall text-light mb-0">Fill in your OVO phone number</p>
    <ion-list lines="full" class="ion-no-margin ion-no-padding">
        <ion-item class="nopadding">
        <ion-input required type="text"></ion-input>
        </ion-item>
    </ion-list>
  </div>
</ion-content>
<ion-footer class="ion-no-border">
	<ion-toolbar class="ion-no-padding">
	  <div class="container">
      <div class="row">
        <div class="col-12">
				  <ion-button routerLink="/email-template" expand="block" class="ion-no-margin" color="primary">Next</ion-button>
				</div>
			</div>
		</div>
  </ion-toolbar>
</ion-footer>
