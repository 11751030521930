<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
    <div class="proj-container-horizontal">
      <h5>{{lang.cart.order_option}}</h5>
    </div>
    <ion-list lines="full">
      <ion-radio-group [(ngModel)]="radio_order">
        <ion-item class="ion-no-margin">
          <ion-radio slot="start" value="2"></ion-radio>
          <ion-label class="ion-text-wrap">
            <div class="text-bold">{{lang.cart.delivery}}</div>
            <div class="text-light">{{lang.cart.delivery_description}}</div>
          </ion-label>
        </ion-item>
        <ion-item class="ion-no-margin">
          <ion-radio slot="start" value="1"></ion-radio>
          <ion-label class="ion-text-wrap">
            <div class="text-bold">{{lang.cart.self_pickup}}</div>
            <div class="text-light">{{lang.cart.self_pickup_description}}</div>
          </ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </div>
</ion-content>

<ion-footer class="box-light" mode="ios">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="confirm()" style="width: 100%;">{{lang.cart.confirmation}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
