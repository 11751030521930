import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Subject, Observable, from } from 'rxjs';
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { Storage } from '@ionic/storage';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private sbj = new Subject<any>();
  private sbjAddress = new Subject<any>();
  language;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private networkService: NetworkService,
    private storage: Storage,
    private offlineManager: OfflineManagerService,
    private languageService: LanguageService
  ) {
    this.language = this.languageService.language();
  }

  getEventList(page: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/event_list?page=${page}`
    );
  }

  getCategoryList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjQ5Mjd9.NGM3NmMxMDJkZjQ0MTllNjcwNjYwYmZmZTUyYzIzMTY1YTkxNjBjODJlZGRiMWVmM2Y4ZjA1YjQ3MDFhZjVhZA`
      })
    };
    return this.http.get<any>(
      `${environment.baseUrl}/api/category/category_list`
    );
  }

  getCategoryListHandlingOffline(forceRefresh: boolean = false) {
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      return from<any>(this.getLocalData(`getCategoryListHandlingOffline`));
    } else {
      return this.http.get<any>(`${environment.baseUrl}/api/category/category_list`)
        .pipe(take(1), tap(res => {
          this.setLocalData('getCategoryListHandlingOffline', res);
        })
        )
    }
  }

  getEventDefault() {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/event_default`
    );
  }

  getHallOfFame(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/hall_of_fame`,
          httpOptions
        );
      })
    );
  }
  
  
  getMenu(eventId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/menu/${eventId}`
    );
  }
  
  getHallOfFameDetail(hallOfFameId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/hall_of_fame/${hallOfFameId}`,
          httpOptions
        );
      })
    );
  }

  getEventResourcesCategory(eventId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/${eventId}/resources_category`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventResources(eventId: number, resourcesCategoryId = null, keyword = null) {
    let param = '';
    if (resourcesCategoryId) {
      param = '?resources_category_id=' + resourcesCategoryId;
      if (keyword) {
        param += '&keyword=' + keyword;
      }
    } else {
      if (keyword) {
        param += '?keyword=' + keyword;
      }
    }



    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/${eventId}/resources${param}`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventResourcesDetail(resourceId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/resources/${resourceId}`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventBlogCategory(eventId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/${eventId}/blog_category`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventBlog(eventId: number, blogCategoryId = null, keyword = null) {
    let param = '';
    if (blogCategoryId) {
      param = '?blog_category_id=' + blogCategoryId;
      if (keyword) {
        param += '&keyword=' + keyword;
      }
    } else {
      if (keyword) {
        param += '?keyword=' + keyword;
      }
    }

    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/${eventId}/blog${param}`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventBlogDetail(blogId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/blog/${blogId}`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }


  getEventCategory(categoryId: number, page: number, date_start: string = '', date_end: string = '', sortby: string = 'new', kabupaten_id: string = '') {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/event_list/${categoryId}?page=${page}&date_start=${date_start.substring(0, 10)}&date_end=${date_end.substring(0, 10)}&sortby=${sortby}&kabupaten_id=${kabupaten_id}`
    );
  }

  getEventCategoryHandlingOffline(forceRefresh: boolean = false, categoryId: number, page: number, date_start: string = '', date_end: string = '', sortby: string = 'new', kabupaten_id: string = '') {
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      return from<any>(this.getLocalData(`getEventCategoryHandlingOffline`));
    } else {
      return this.http.get<any>(`${environment.baseUrl}/api/${this.language}/event/event_list/${categoryId}?page=${page}&date_start=${date_start.substring(0, 10)}&date_end=${date_end.substring(0, 10)}&sortby=${sortby}&kabupaten_id=${kabupaten_id}`)
        .pipe(take(1), tap(res => {
          this.setLocalData('getEventCategoryHandlingOffline', res);
        })
        )
    }
  }

  pubFilter(data: any) { this.sbj.next(data); }
  obvFilter(): Subject<any> { return this.sbj; }


  pubAddress(data: any) { this.sbjAddress.next(data); }
  obvAddress(): Subject<any> { return this.sbjAddress; }

  getSearchEvent(search: string, category_id: any = 0, page: any = 1) {
    let body = new FormData();
    body.append('page', page);
    body.append('search', search);
    body.append('language', this.language)
    if (category_id != 0) {
      body.append('category_id', category_id);
    }
    return this.http.post<any>(
      `${environment.baseUrl}/api/event/search_event`, body
    );
  }

  getEventDetail(eventId: number) {
    return this.http.get<any>(
      `${environment.baseUrl}/api/${this.language}/event/${eventId}`
    )
      .pipe(
        map(resData => {
          return resData.data;
        })
      );
  }

  getEventTicket(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/tickets/${eventId}`,
          httpOptions
        );
      })
    );
  }

  getEventServiceCharge(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.baseUrl}/api/event/service_charge/${eventId}`,
          httpOptions
        );
      })
    );
  }



  buyTicket(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('event_id', params.event_id);
        body.append('billing_name', params.billing_name);
        body.append('billing_mail', params.billing_mail);
        body.append('billing_phone', params.billing_phone);
        params.ticket.forEach(ticket => {
          body.append('ticket_id[]', ticket.ticket_id);
          body.append('ticket_qty[]', ticket.ticket_qty);
        });

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/buyticket`,
          body,
          httpOptions
        );
      })
    );
  }
 
  checkVoucher(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('voucher_code', params.voucher_code);
        body.append('event_id', params.event_id);
        params.ticket_id.forEach(ticket_id => {
          body.append('ticket_id[]', ticket_id);
        });

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/${params.event_id}/checkVoucher`,
          body,
          httpOptions
        );
      })
    );
  }


  buyTicketV2(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('member_id', params.member_id);
        body.append('event_id', params.event_id);
        body.append('is_myticket', '[' + params.is_myticket + ']');
        body.append('payment_id', params.payment_id);
        params.ticket.forEach(ticket => {
          body.append('ticket_id[]', ticket.ticket_id);
          body.append('ticket_qty[]', ticket.ticket_qty);
        });
        if (params.phone) {
          body.append('billing_phone', params.phone);
        }
        if (params.token_id) {
          body.append('token_id', params.token_id);
        }
        if (params.authentication_id) {
          body.append('authentication_id', params.authentication_id);
        }
        if (params.card_cvn) {
          body.append('card_cvn', params.card_cvn);
        }
        if (params.voucher_code) {
          body.append('voucher_code', params.voucher_code);
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        // return this.http.post<any>(
        //   `${environment.baseUrl}/api/v2/event/buyticket`,
        //   body,
        //   httpOptions
        // );
        return this.http.post<any>(
          `${environment.API2Url}/kelolain_transaction/add_transaction_ticket`,
          body,
          httpOptions
        );
      })
    );
  }

  getMyEvent(status: string) {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/member/event/${status}`,
          httpOptions
        );
      })
    );
  }

  getMyEventHandlingOffline(forceRefresh: boolean = false, status: string) {
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
      return from<any>(this.getLocalData(`getMyEventHandlingOffline`));
    } else {
      return this.authService.token.pipe(
        switchMap(token => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${token}`
            })
          };
          return this.http.get<any>(
            `${environment.baseUrl}/api/${this.language}/member/event/${status}`,
            httpOptions)
            .pipe(take(1), tap(res => {
              this.setLocalData('getMyEventHandlingOffline', res);
            })
            )
        })
      );
    }
  }

  getMaterialList(eventId: number, type: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/workshopmaterial/${type}`, httpOptions
        );
      })
    );
  }

  getBrochureList(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/brochure_list`, httpOptions
        );
      })
    );
  }

  getBrochureDetail(brochureId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/brochure/${brochureId}`, httpOptions
        );
      })
    );
  }

  getFaqList(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/${eventId}/faq`, httpOptions
        );
      })
    );
  }

  getFaqDetail(faqId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/faq/${faqId}`, httpOptions
        );
      })
    );
  }

  getMyPointList(eventId: number, page = 1) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/${eventId}/point/history?page=${page}`, httpOptions
        );
      })
    );
  }

  getLeaderboardList(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/point/leaderboard`, httpOptions
        );
      })
    );
  }

  checkQRcode(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('qrcode', params.qrcode);
        body.append('join_event', params.join_event);
        body.append('language', this.language);

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/scan_ticket`, body, httpOptions
        );
      })
    );
  }

  checkMemberHasEvent(eventId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/memberhasevent/${eventId}`, httpOptions
        );
      })
    );
  }
  
  
  getTopic(eventId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/topic`, httpOptions
        );
      })
    );
  }
  
  getTopicDetail(eventId,topicId) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/event/${eventId}/group_live_chat/${topicId}`, httpOptions
        );
      })
    );
  }

  cartList(eventId: number) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
            // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjQ5Mjd9.NGM3NmMxMDJkZjQ0MTllNjcwNjYwYmZmZTUyYzIzMTY1YTkxNjBjODJlZGRiMWVmM2Y4ZjA1YjQ3MDFhZjVhZA`
          })
        };
        return this.http.get<any>(
          `${environment.baseUrl}/api/${this.language}/event/${eventId}/shop/cart`, httpOptions
        );
      })
    );
  }
  pubDeliveryOption(data: any) { this.sbj.next(data); }
  obvDeliveryOption(): Subject<any> { return this.sbj; }

  cartAdd(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('event_id', params.event_id);
        body.append('product_id', params.product_id);
        body.append('product_qty', params.product_qty);
        body.append('notes', params.notes);
        body.append('delivery_option', params.delivery_option);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/shop/cart/add`,
          body,
          httpOptions
        );
      })
    );
  }

  cartUpdate(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('cart_id', params.cart_id);
        body.append('product_qty', params.product_qty);
        body.append('notes', params.notes);
        body.append('delivery_option', params.delivery_option);
        body.append('member_address_id', '');

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/shop/cart/update`,
          body,
          httpOptions
        );
      })
    );
  }


  cartDelete(params: any) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('cart_id', params.cart_id);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/shop/cart/delete`,
          body,
          httpOptions
        );
      })
    );
  }

  scanQRCode(qrcode) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('qrcode', qrcode);
        body.append('language', this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/scan_qrcode`,
          body,
          httpOptions
        );
      })
    );
  }

  searchEventByQR(qrcode) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('qrcode', qrcode);
        body.append('language', this.language);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/search`,
          body,
          httpOptions
        );
      })
    );
  }


  checkVersion(app_version, device_type) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('app_version', app_version);
        body.append('device_type', device_type);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/apps/check_version`,
          body,
          httpOptions
        );
      })
    );
  }


  getFixedPrice(kabupaten_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('kabupaten_id', kabupaten_id);

        return this.http.post<any>(
          `${environment.API3Url}/get_data/get_fixed_price`,
          body
        );
      })
    );
  }

  getEventHomeMenu(event_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('language', this.language);
        body.append('event_id', event_id);
        body.append('show', '2'); //1=app, 2=web

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/member_menu`, body, httpOptions
        );
      })
    );
  }

  activityTrack() {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/member/activity/track`, body, httpOptions
        );
      })
    );
  }

  getTextAreaContent(text_area_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('text_area_id', text_area_id);
        body.append('language', this.language);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/menu/textarea`, body, httpOptions
        );
      })
    );
  }

  RSVPEvent(event_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('event_id', event_id);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/rsvp/question`, body, httpOptions
        );
      })
    );
  }

  submitRSVP(params) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('event_id', params.event_id);
        body.append('ticket_id', params.ticket_id);
        params.questions.forEach(q => {
          body.append('question_id[]', q.question_id);
          body.append('question_answer[]', q.result);
        });
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/rsvp/submit`, body, httpOptions
        );
      })
    );
  }

  getRSVPAnswer(guest_id) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('guest_id', guest_id);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.baseUrl}/api/event/rsvp/get`, body, httpOptions
        );
      })
    );
  }


  private setLocalData(key, data) {
    this.storage.set(`${environment.baseUrl}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${environment.baseUrl}-${key}`);
  }

}
