import { Injectable } from '@angular/core';
import { Platform, LoadingController, ToastController } from '@ionic/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { switchMap } from 'rxjs/operators';
//import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  CameraResultType, CameraSource} from '@capacitor/core';
import { AuthService } from './auth.service';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';

import { environment } from 'src/environments/environment';
import { Zoom } from '@ionic-native/zoom/ngx';

const { Camera } = Plugins;
const { PushNotifications } = Plugins;
const { LocalNotifications } = Plugins;
const { Browser } = Plugins;
const { Device } = Plugins;
const { SignInWithApple } = Plugins;
const { Share } = Plugins;
const { Clipboard } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NativeService {

  constructor(
    private barcodeScanner: BarcodeScanner,
    private emailComposer: EmailComposer,
    private geolocation: Geolocation,
    private locationAccuracy: LocationAccuracy,
    private androidPermissions: AndroidPermissions,
    //private camera: Camera,
    private fileOpener: FileOpener,
    private transfer: FileTransfer,
    private file: File,
    private authService: AuthService,
    private streamingMedia: StreamingMedia,
    private platform: Platform,
    private zoom: Zoom,
    private toastCtrl: ToastController,
    public loadingController: LoadingController
  ) { }

  scan() {
    return new Promise((resolve, reject) => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.barcodeScanner.scan({
          showTorchButton: true,
          resultDisplayDuration: 0
        }).then(barcodeData => {
          resolve(barcodeData);
        }).catch(err => {
          reject(err);
        });
      } else {
        reject("Web not Support for this feature");
      }

    });


  }

  // Open call
  openCall(number) {
    window.open('tel:'+number);
  }

  // Open email composer
  openEmail(email, subject) {
    let mailOpt = {
      to: email,
      subject: 'Kelolain - ' + subject,
      isHtml: true
    }
    // Send a text message using default options
    this.emailComposer.open(mailOpt);
  }

  // Open browser
  async openUrl(url) {
    let protocol = url.substring(0, 4);
    if (protocol == "http") {
      await Browser.open({ url: url });
    } else {
      await Browser.open({ url: 'https://' + url });
    }
  }

  async getDeviceInfo() {
    const info = await Device.getInfo();
    return new Promise(resolve => {
      resolve(info);
    });
  }

  async openCamera(source) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      let sourceType;
      let correctOrientationOption;
      let allowEditingOption;

      if (source == 0) {
        sourceType = CameraSource.Camera
        if (this.platform.is('android')) {
          allowEditingOption = true;
        }
      } else {
        sourceType = CameraSource.Photos
      }

      if (this.platform.is('android')) {
        correctOrientationOption = true;
      }

      if (this.platform.is('ios')) {
        allowEditingOption = true;
      }

      const image = await Camera.getPhoto({
        quality: 15,
        allowEditing: allowEditingOption,
        resultType: CameraResultType.Uri,
        source: sourceType,
        correctOrientation: correctOrientationOption
      });


      return new Promise((resolve, reject) => {
        var imageUrl = image.path;
        resolve(imageUrl);
      });


    }
    // else {
    //   reject("Web not Support for this feature");
    // }



  }

  getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        if (this.platform.is('android')) {
          this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
            result => {
              if (result.hasPermission) {
                this.askToTurnOnGPS(resolve);
              } else {
                this.requestGPSPermission(resolve);
              }
            },
            err => {
              alert(err);
            });
        } else {
          this.askToTurnOnGPS(resolve);
        }
      } else {
        this.geolocation.getCurrentPosition().then((resp) => {
          let currentLocation = {
            lat: resp.coords.latitude,
            lon: resp.coords.longitude,
            accuracy: resp.coords.accuracy,
            timestamp: resp.timestamp
          };
          resolve(currentLocation);
        }).catch((error) => {
          console.log('Error getting location', error);
          reject('Error getting location' + error);
        });
      }

    });


  }

  requestGPSPermission(resolve) {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log("4");
      } else {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
          () => {
            this.askToTurnOnGPS(resolve);
          },
          error => {
            resolve(false);
            alert('requestPermission Error requesting location permissions ' + error)
          }
        );
      }
    });
  }

  askToTurnOnGPS(resolve) {
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(() => {
      this.geolocation.getCurrentPosition().then((resp) => {
        let currentLocation = {
          lat: resp.coords.latitude,
          lon: resp.coords.longitude,
          accuracy: resp.coords.accuracy,
          timestamp: resp.timestamp
        };
        resolve(currentLocation);
      }).catch((error) => {
        console.log('Error getting location', error);
      });
    },
      error => {
        resolve(false);
        alert('Error requesting location permissions ' + JSON.stringify(error))
      }
    );
  }


  /* Push notification */
  // Push notif
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  fcmInit() {
    return new Promise(resolve => {
      if (this.platform.is('capacitor')) {
        PushNotifications.requestPermission().then(result => {
          // if (result.granted) {
          console.log('result' + result);
          if (result) {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });

        PushNotifications.addListener('registration',
          (token: PushNotificationToken) => {
            console.log('Token: ' + token.value);
            resolve(token.value);
          }
        );

        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
        );

        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotification) => {
            console.log('Push received: ' + JSON.stringify(notification));
            console.log('Push received: data' + JSON.stringify(notification.data));
            if (this.platform.is('android') || this.platform.is('ios')) {
              this.scheduleNowWithIcon(0, notification.title, notification.body, notification.data.schedule);
            }
          }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: PushNotificationActionPerformed) => {
            console.log('Push action performed: ' + JSON.stringify(notification));
          }
        );
      }
    });
  }

  async scheduleNowWithIcon(id, title, body, date) {
    if(id == 0 ){
      id = Math.floor(Math.random() * 10)
    }

    const notifs = await LocalNotifications.schedule({
      notifications: [{
        title: title,
        body: body,
        smallIcon: "res://drawable/ic_stat_name",
        schedule: { at: new Date(date) },
        id: id
      }]
    });

  }

  async cancelNotif(id){
    await LocalNotifications.cancel({
      notifications: [
        { id: id}
      ]
    });

  }



  checkFile(fileName, path) {
    //let filePath = this.file.externalDataDirectory + fileName;
    //let path = this.file.externalDataDirectory;

    //console.log(path + fileName);
    return new Promise((resolve, reject) => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.file.resolveLocalFilesystemUrl(path + fileName)
          .then(res => {
            console.log("OK")
            resolve(true)
          }).catch(err => {
            console.log("KO")
            resolve(false)
          });
      } else {
        reject("Web not Support for this feature");
      }

    });



  }

  pdfOpen(url) {
    let name = url.substring(url.lastIndexOf('/') + 1);
    const val_url = url;
    let path = this.file.dataDirectory;

    if (this.platform.is('android') || this.platform.is('ios')) {
      this.loadingController.create({ keyboardClose: true })
      .then(loading => {
        if (this.platform.is('android')) {
          this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
            result => {
              if (result.hasPermission) {
                loading.present();
                this.checkFile(name, path).then((resp) => {
                  if (!resp) {
                    this.downloadOpenFile(val_url, path, name, loading);
                  } else {
                    this.fileOpener.open(path + name, 'application/pdf')
                      .then(() => this.pdfOpenSuccess(loading))
                      .catch(e => this.pdfOpenFailed(loading,e));
                  }
                });

              } else {
                this.requestStoragePermission(url);
              }
            },
            err => {
              alert(err);
            });
        } else {
          this.downloadOpenFile(val_url, path, name, loading);
        }
      });
    } else {
      console.log("Web not Support for this feature");
      this.fileOpener.open(path + name, 'application/pdf')
        .then(() => console.log('File is opened'))
        .catch(e => console.log(e));
    }

  }
  pdfOpenSuccess(loading){
    console.log('File is opened');
    loading.dismiss();
  }
  pdfOpenFailed(loading,e){
    console.log('Error opening file', e);
    loading.dismiss();
  }

  requestStoragePermission(url) {
    this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE]).then(
      () => {
        if (this.androidPermissions.hasPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
          && this.androidPermissions.hasPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)) {
          this.pdfOpen(url)
        }
      },
      error => {
        alert('requestPermission Error requesting storage permissions ' + error)
      }
    );
  }

  downloadOpenFile(val_url, path, filename, loading) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      const fileTransfer: FileTransferObject = this.transfer.create();
      fileTransfer.download(val_url, `${path}${filename}`).then((entry) => {
        this.fileOpener.open(entry.toURL(), 'application/pdf')
          .then(() => this.pdfOpenSuccess(loading))
          .catch(e => this.pdfOpenFailed(loading,e));
      }, (error) => {
        console.log(JSON.stringify(error));
      });
    }

  }

  uploadFile(endPoint, file, imageKey, params) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      const fileTransfer: FileTransferObject = this.transfer.create();
      return this.authService.token.pipe(
        switchMap(token => {

          let options: FileUploadOptions = {
            httpMethod: "POST",
            headers:{
              'Authorization': `Bearer ${token}`
            },
            fileKey: imageKey,
            chunkedMode: false,
            params: params
          }

          return new Promise((resolve, reject) => {
            fileTransfer.upload(file, `${endPoint}`, options)
              .then((data) => {
                resolve(data);
              }, (err) => {
                reject(err);
              });

          });
        })
      );
    }

  }

  playVideoStream(url) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      let options: StreamingVideoOptions = {
        successCallback: () => { console.log('Video played') },
        errorCallback: (e) => { console.log('Error streaming') },
        orientation: 'portrait',
        shouldAutoClose: true,
        controls: true
      };

      this.streamingMedia.playVideo(url, options);
    }

  }

  loginApple() {
    return new Promise((resolve, reject)  => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        SignInWithApple.Authorize().then(response => {
          resolve(response);
        }).catch(response => {
          console.error(response);
        })
      } else {
        reject("Web not Support for this feature");
      }
    })
  }

  async share(eventName, text) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      await Share.share({
        title: 'Nextgen - ' + eventName,
        text: text,
        dialogTitle: 'Share with your friends'
      });
    } else {
      await this.copyToClipboard('Nextgen - ' + eventName + '\n' + text);
      this.showToast("Share Link Copied")
    }

  }

  async copyToClipboard(text) {
    await Clipboard.write({
      string: text
    });
  }

  async getClipboard() {
    let result = await Clipboard.read();
    return new Promise(resolve => {
      resolve(result)
    })
  }

  zoomMeeting(meeting_id, password, name) {
    let options = {
      no_share: false,
      no_audio: false,
      no_video: false,
      no_driving_mode: true,
      no_invite: true,
      no_meeting_end_message: true,
      no_dial_in_via_phone: false,
      no_dial_out_to_phone: false,
      no_disconnect_audio: true,
      no_meeting_error_message: true,
      no_unmute_confirm_dialog: true,    // Android only
      no_webinar_register_dialog: false, // Android only
      no_titlebar: false,
      no_bottom_toolbar: false,
      no_button_video: false,
      no_button_audio: false,
      no_button_share: false,
      no_button_participants: false,
      no_button_more: false,
      no_text_password: true,
      no_text_meeting_id: false,
      no_button_leave: false
    };
    return new Promise((resolve, reject) => {
      this.zoom.joinMeeting(meeting_id, password, name, options)
        .then((success: any) => {
          console.log(success)
          resolve(success);
        })
        .catch((error: any) => {
          console.log(error)
          reject(error);
        });
    });

  }

  zoomClose(){
    this.zoom.logout();
  }

  showToast(message) {
    this.toastCtrl.create({
      message: message,
      duration: 2000
    }).then(toast => toast.present());
  }
}
