import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EventService } from '../../../services/event.service';
import { AccountService } from '../../../services/account.service';

import { PopupCalendarPage } from '../../dashboard/popup-calendar/popup-calendar.page';
import { PopupLocationPage } from '../../dashboard/popup-location/popup-location.page';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-popup-filter',
  templateUrl: './popup-filter.page.html',
  styleUrls: ['./popup-filter.page.scss'],
})
export class PopupFilterPage implements OnInit {
  @ViewChild('timePicker') timePicker;
  keyword = '';
  mdl_date: string;

  data_kota: any = [];
  data_filtered: any = [];
  radio_city: any;
  mdl_date_start: any;
  mdl_date_end: any;
  filter: any;

  location: any = [];
  maxData : any;
  lang: any = {};

  constructor(
    private accountService: AccountService,
    private eventService: EventService,
    public modalController: ModalController,
    public navParams: NavParams,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('dashboard').subscribe(res => { this.lang = res} );
  }

  ngOnInit() {
    this.filter = this.navParams.get('filter');
    // if (this.filter.date_start != '')
    //   this.mdl_date_start = this.filter.date_start;
    // else
    //   this.mdl_date_start = new Date().toISOString();

    // if (this.filter.date_end != '')
    //   this.mdl_date_end = this.filter.date_end;
    // else
    //   this.mdl_date_end = new Date().toISOString();

    this.location = this.filter.location;
    this.maxData = (new Date().getFullYear() + 1).toString();


  }

  cariLokasi(ev: any) {
    if (ev.target.value == '' || ev.target.value.length < 3) {
      this.data_kota = [];
      return;
    } else {
      this.data_filtered = [];

      const params = {
        search: ev.target.value,
      };
      this.accountService.getLocationList(params).subscribe(resData => {
        this.data_kota = resData.data;
        let last_id;
        this.data_kota.forEach(e => {
          if (this.data_filtered.length == 0) {
            this.data_filtered.push(e);
            last_id = e.kabupaten_id;
          } else {
            if (e.kabupaten_id != last_id) {
              this.data_filtered.push(e);
              last_id = e.kabupaten_id;
            }
          }
        });




        // console.info(JSON.stringify(this.data_filtered));
      });
    }
  }

  popupClose() {
    this.modalController.dismiss();
  }

  submitFilter() {
    let kabupaten_id = '';
    this.location.forEach(e => {
      if (kabupaten_id == '')
        kabupaten_id = e.kabupaten_id;
      else
        kabupaten_id = kabupaten_id + ',' + e.kabupaten_id;
    });

    this.eventService.pubFilter({
      date_start: this.mdl_date_start,
      date_end: this.mdl_date_end,
      kabupaten_id: kabupaten_id,
      sortby: this.filter.sortby,
      location: this.location
    });

    this.modalController.dismiss();
  }

  showTimePicker() {
    this.timePicker.open();
  }

  addLocation(location) {
    let c = 0;
    this.location.forEach(e => {
      if (e.kabupaten_id == location.kabupaten_id)
        c = 1;
    });
    if (c == 0) {
      this.location.push(location);
    }
    this.data_filtered = [];
    this.keyword = '';
  }

  removeLocation(index) {
    if (index > -1) {
      this.location.splice(index, 1);
    }
  }

  reset() {
    this.keyword = '';
    this.mdl_date = '';
    this.data_kota = [];
    this.data_filtered = [];
    // this.radio_city: any;
    this.mdl_date_start = new Date().toISOString();
    this.mdl_date_end = new Date().toISOString();
    this.location = [];

    this.eventService.pubFilter({
      date_start: '',
      date_end: '',
      kabupaten_id: '',
      sortby: this.filter.sortby,
      location: []
    });
    this.modalController.dismiss();

  }

  // async popupCalendar() {
  //   const modal = await this.modalController.create({
  //     component: PopupCalendarPage,
  //     backdropDismiss: true,
  //     animated: true,
  //     cssClass: 'custom-modal-height'
  //   });
  //   return await modal.present();
  // }

  async popupLocation() {
    const modal = await this.modalController.create({
      component: PopupLocationPage,
      backdropDismiss: true,
      animated: true,
      cssClass: 'custom-modal-height'
    });
    return await modal.present();
  }

}
