import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { SessionService } from 'src/app/services/session.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-modalquestion',
  templateUrl: './modalquestion.page.html',
  styleUrls: ['./modalquestion.page.scss'],
})
export class ModalquestionPage implements OnInit {
  @Input() event_id;
  @Input() session_id;

  constructor(
    private modalController: ModalController,
    private firestore: AngularFirestore,
    private sessionService: SessionService,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  askQuestion(form: NgForm) {
    if (form.value.question == '') {
      return;
    }
    const question = form.value.question;
    this.sessionService.askQuestion(this.session_id,question)
    .subscribe(
      res => {
        const data = {
          session_question_id: res.data.session_question_id,
          event_id: res.data.event_id
        };
        this.firestore.collection("kelola_qna").add(data)
        .then();
        this.sessionService.getQuestionByQuestionId(res.data.session_question_id)
        .subscribe(
          res => {
          }
        );
      }
    );
    form.reset();
    this.presentToast('Question Sent');
    this.closeModal();
  }

  presentToast(message) {
    this.toastCtrl.create({
      message: message,
      duration: 2000
    }).then(toast => toast.present());
  }

}
