import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-popup-shipping',
  templateUrl: './popup-shipping.page.html',
  styleUrls: [ './popup-shipping.page.scss' ],
})
export class PopupShippingPage implements OnInit {

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  popupClose() {
    this.modalController.dismiss();
  }

}
